import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { SalesForceService } from '@services/salesforce.service';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ConfigurationService } from '../common-modules/services/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class SalesForceFaiService {

    constructor(private router: Router,
                private faiEligibilityService: FaiEligibilityService,
                private saleforceService: SalesForceService,
                private configService: ConfigurationService) {
    }

    public resolve(): Observable<boolean> {
        if (!this.faiEligibilityService.currentCart && !this.configService?.data_refconf?.fai?.widget?.active &&
            this.saleforceService.prefilledInfo.salesType !== 'MOBILE' && this.saleforceService.prefilledInfo.address){
            return this.saleforceService.replayEligibilityFromData(this.saleforceService.prefilledInfo.address)
                .pipe(
                    filter(status=>status),
                    tap(()=>{
                        this.saleforceService.setFaiHousingAllReadyDisplayed();
                        this.router.navigate([
                            'fai',
                            this.faiEligibilityService.currentCart?.eligibility ? 'housing' : 'address'
                        ]);
                    }));
        }
        if (this.saleforceService.prefilledInfo.salesType === 'FAI' &&
            this.faiEligibilityService.currentCart &&
            !this.saleforceService.prefilledInfo.faiHousingDisplayed
        ){
            this.saleforceService.setFaiHousingAllReadyDisplayed();
            this.router.navigate([
                'fai',
                this.faiEligibilityService.currentCart?.eligibility ? 'housing' : 'address'
            ]);
            return EMPTY;
        }
        return of(true);
    }
}
