import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderModel } from '@models/order/order.model';
import { SalesForceService } from '@services/salesforce.service';
import { FAI_WIDGET_ROUTES } from 'src/app/constants/fai';
@Component({
    selector: 'tlv-weto-header',
    templateUrl: './weto-header.component.html',
    styleUrls: ['./weto-header.component.scss']
})
export class WetoHeaderComponent {

    @Input() firstStepValue: boolean;
    @Input() currentStep: string;
    @Output() onBackEvent: EventEmitter<any> = new EventEmitter<any>();

    public CTA_BACK_LABELS: { [index: string]: string } = {
        [FAI_WIDGET_ROUTES.FUNNEL]: '',
        [FAI_WIDGET_ROUTES.NUMBER]: '',
        [FAI_WIDGET_ROUTES.HOUSING]: 'Recherche de l\'occupant',
        [FAI_WIDGET_ROUTES.PTO]: 'Recherche de PTO',
    };
    public orderRecovery: OrderModel;

    constructor(protected salesForceService: SalesForceService) {
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
    }

    public back(): void {
        this.onBackEvent.emit();
    }

}
