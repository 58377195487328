import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Activation,
    CUSTOMER_CATEGORY,
    CartModel,
    Fai,
    FaiBox,
    Installation,
    Plan,
    Product,
    QUOTE_CONTEXTS,
    QuoteContextFaiModel,
    TYPE_MARKETING
} from '@bytel/bytel-sales';
import bind from '@helper/decorators/bind';
import { OrderModel } from '@models/order/order.model';
import { CatalogService } from '@services/catalog.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CartService } from '@services/checkout/cart.service';
import { ScoringService } from '@services/checkout/scoring.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { SalesForceService } from '@services/salesforce.service';
import { Observable, Subscription, catchError, forkJoin, map, merge, mergeMap, of, startWith, tap, throwError } from 'rxjs';
import { EQUIPMENT_FILTER_LABELS, FAI_ROUTES } from 'src/app/constants/fai';
import {
    Resultat
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-offres-autorisees/dist/models/components/schemas/Resultat';
import {
    FaiCategoryType,
    IContextualizedProductsParams,
    ISapicFaiProduct,
    ISapicFaiProductPromotion,
    TYPE_ELIGIBILITE
} from '@interfaces/products.interface';
import { CustomerService } from '@services/customer/customer.service';
import {ALLOWED_CATEGORIES} from '@interfaces/api/catalog.interface';
import {CatalogType} from '@repositories/sales.repository';
import { ModeFinancementEnum } from '@components/products-walls/products-walls.component';
import { PromotionsService } from '@services/promotions.service';
import { ConfigurationService } from '@common-modules';

export interface IFaiPackBundle {
    cart: CartModel;
    fai: Fai;
    ocProduct: ISapicFaiProduct;
    manualPromotionsDetails?: {[key: string]: any[]};
}

export interface IFaiBundle {
    cart: CartModel;
    fai: Fai;
    activation?: Activation;
    installation?: Installation;
    location: FaiBox;
    skus: string[];
}

export interface IFaiOffers { [key: string]: IFaiPackBundle[] }

enum ConvergenceTypes {
    STANDARD = 'STANDARD',
    PREMIUM = 'PREMIUM',
}

@Component({
    selector: 'tlv-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnDestroy {

    public selectedTech;
    public field = 'ocProduct.prix.pourToujours';
    public offers: IFaiOffers = {};
    public offerKeys: string[] = [];
    public reverse = true;
    public isLoading: boolean = false;
    public selectedOffer;
    public orderRecovery: OrderModel;
    public technoFilter: string[] = [];
    public canAddFaiInQuoteMixed: boolean = false;
    public noOffersCategoryError: boolean = false;
    public offerWithManualPromotionZipCode: string[] = [];
    public proOfferLabel: string = 'offre_pro';
    public filterOffersByType: FormGroup = new FormGroup({
        offerType: new FormControl(
            'offre_gp', []
        ),
        sortByObligation: new FormControl(
            'all', []
        ),
        sortByEquipmentType: new FormControl(
            'all', []
        ),
        sortByPartner: new FormControl(
            'none', []
        ),
    });
    public offersTypes: {type: string; label: string}[] = [
        {type: 'gp', label: 'Offres pour GP'},
        {type: 'pro', label: 'Offres pour PRO'},
        {type: 'special', label: 'Offre spéciale'},
        {type: 'old', label: 'Historique offre'}
    ];
    public filterEquipements: { [index: string]: string } = EQUIPMENT_FILTER_LABELS;
    public sortByPartners: { label: string; key: string }[] = [];
    public sortByObligations: { label: string; key: string }[] = [{ label: 'Toutes les durées d\'engagement', key: 'all' }];
    public scoringRules: Resultat;
    public CONVERGENCE_TYPES = ConvergenceTypes;
    public TYPE_ELIGIBILITE = TYPE_ELIGIBILITE;

    private _isEligibleToQuoteMixed: boolean = false;
    private _bundlesBacked: IFaiOffers = {};
    private _subscriptions: Subscription[] = [];

    constructor(
        private readonly faiEligibility: FaiEligibilityService,
        private readonly catalogService: CatalogService,
        private readonly cartService: CartService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly scoringService: ScoringService,
        private readonly salesForceService: SalesForceService,
        private cartTelesalesService: CartTeleSalesService,
        private promotionsService: PromotionsService,
        private configurationService: ConfigurationService,
        protected customerService: CustomerService,
        protected router: Router) {
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        this.activatedRoute.queryParams.subscribe((data) => {
            this.technoFilter = data?.techno?.split(',').filter((tech: string) => !!tech);
        });

        this._subscriptions.push(
            this.cartTelesalesService.isEligibleToQuoteMixed()
                .subscribe({
                    next: (data) => this._isEligibleToQuoteMixed = data && this.cartTelesalesService.hasSimoInCart()
                })
        );

        this._subscriptions.push(
            merge(
                this.filterOffersByType.get('sortByObligation').valueChanges,
                this.filterOffersByType.get('sortByEquipmentType').valueChanges,
                this.filterOffersByType.get('sortByPartner').valueChanges
            ).subscribe({
                next: () => {
                    this.offers = this._updateFilteredOffers(Object.keys(this._bundlesBacked), this._bundlesBacked);
                    this.offerKeys = this._orderOffersKeys(
                        Object.entries(this.offers).filter(([,offers])=>!!offers.length).map(([offersKeys,])=>offersKeys)
                    );
                }
            })
        );

        this._subscriptions.push(
            this.filterOffersByType.get('offerType').valueChanges
                .pipe(startWith(this.filterOffersByType.getRawValue())) // Trigger form change
                .subscribe({
                    next: () => this._getOffers(this.faiEligibility.currentCart.eligibility.getOCs()).subscribe()
                })
        );
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe());
    }

    public getNameOfKey(key: string): string {
        return key
            .replace('/box_4g/gi', 'Box 4G')
            .replace('/box_5g/gi', 'Box 5G')
            .replace(/_undefined/gi, '')
            .replace('_exclu_asso', ' (Exclu asso)')
            .replace('_smart_tv', ' (Smart Tv)')
            .replace('/3P/gi', 'TV - Téléphonie fixe - Internet')
            .replace('/2P/gi', 'Téléphonie fixe - Internet')
            .replace('_', ' ');
    }

    public toNumber(value?: string): number {
        return (value) ? parseFloat(value) : 0;
    }

    public toggleOffer(offer: string): void {
        this.selectedOffer = offer === this.selectedOffer ? null : offer;
    }

    public trackByFn(index: number, item: ISapicFaiProduct): string {
        return item.gencode;
    }

    public back(): void {
        this.router.navigateByUrl(FAI_ROUTES.TECHNO);
    }

    public updateBundle(offer: IFaiPackBundle, offerKey: string, index: number): void {
        this.offers[offerKey][index] = offer;
    }

    @bind
    private _generateOfferKey(offer: IFaiPackBundle): string {
        const ocProduct: ISapicFaiProduct = offer.ocProduct;
        let key: string = `${ocProduct.technologie}_${ocProduct.jeu}`;
        const smartTvRangesLines: string[] = [
            'bbox_tv_plus',
            'bbox_smart_tv',
            'bbox_smart_tv_large',
            'sl_large_smart_tv'
        ];

        if (ocProduct.excluAsso) {
            key += '_exclu_asso';
        }
        if (smartTvRangesLines.includes(ocProduct?.gamme?.replace(/\s/g, '_').toLowerCase())) {
            key += '_smart_tv';
        }
        return key;
    }

    @bind
    private _createAndGroupOffers(offers: IFaiPackBundle[]): Observable<{offers: IFaiOffers; offersKeys: string[]}> {
        const rawOfferskeys: string[] = Array.from(new Set(offers.map(this._generateOfferKey)));

        const offersKeys: string[] = this._orderOffersKeys(rawOfferskeys);
        const results: IFaiOffers = {};

        offers.forEach((offer: IFaiPackBundle) => {
            const key: string = this._generateOfferKey(offer);
            if (!results[key]) {
                results[key] = [];
            }
            results[key].push(offer);
        });
        return of({offers: results, offersKeys });
    }

    private _orderOffersKeys(keys: string[]): string[] {
        const orderTechno: string[] = [
            'FTTH_3P',
            'FTTH_2P_smart_tv',
            'FTTH_2P',
            'FTTH_2P_exclu_asso',
            'VDSL_3P',
            'VDSL_2P',
            'VDSL_2P_exclu_asso',
            'ADSL_3P',
            'ADSL_2P',
            'ADSL_2P_exclu_asso',
            'BOX_5G_undefined',
            'BOX_4G_undefined',
            'THD_3P'
        ];
        return keys.sort((a, b) =>
            orderTechno.indexOf(a) - orderTechno.indexOf(b)
        );
    }

    private _selectRecoveryOffer(): void {
        if (this.orderRecovery) {
            this.toggleOffer(this.orderRecovery?.fai?.offer?.id);
        }
    }

    @bind
    private _createFaiBundle(ocProduct: ISapicFaiProduct): Observable<IFaiPackBundle> {
        ocProduct.promotions = ocProduct.promotions.filter(p=>!p.estIncitation);
        const fai: Fai = this._mapToJsonProduct(ocProduct);
        // refresh set to false to avoid promotion load
        return this.cartService.generateCart([fai], false, new QuoteContextFaiModel(), false)
            .pipe(map(cart => ({
                ocProduct,
                fai,
                cart,
                manualPromotionsDetails: {}
            })));
    }

    private _getOffers(gencodes: string[]): Observable<any> {

        const headerRequete = this._buildReqHeader(gencodes);
        this._assignOffers();
        this.isLoading = true;

        forkJoin([
            this.catalogService.getCategoriesAndChilds<Product>([ALLOWED_CATEGORIES.SIMOFAI_TELESALES], true),
            this.catalogService.getContextualizedProductsOrigin<ISapicFaiProduct>(headerRequete, this._generateBodyParamsFromCart()),
            this.cartTelesalesService.canAddFaiInQuote(),
        ]).pipe(
            map(([
                multiQuotesProcusts, {produits: products}, canAddFaiInQuoteMixed
            ]) => {
                this.canAddFaiInQuoteMixed = canAddFaiInQuoteMixed;
                // @TODO: USELESS ?
                if (this.canAddFaiInQuoteMixed) {
                    return products.filter(pOCs => multiQuotesProcusts[0].products.find(p => pOCs.gencode === p.gencode));
                }
                return products;
            }),
            tap((offers) => offers.forEach(this._createManualPromotionZipCode)),
            mergeMap(productsOCs => productsOCs.length ? forkJoin(productsOCs.map(this._createFaiBundle)) : of([])),
            mergeMap((productsOCs) => forkJoin(productsOCs.map(this._getPromotionsDetails))),
            mergeMap((productsOCs) => this._createAndGroupOffers(productsOCs)),
            catchError((err) => {
                console.log(err);
                this.noOffersCategoryError = true;
                this.isLoading = false;
                this._assignOffers();
                this.offers = this._bundlesBacked;
                return throwError(() => new Error('Aucune offre n\'a été trouvée pour cette catégorie.'));
            })
        ).subscribe({
            next: ({ offers, offersKeys }) => {
                this.noOffersCategoryError = !offersKeys.length;
                this._assignOffers(offersKeys, offers);

                const allOffersArray = Object.values(this.offers).flat(2);
                this._updateEquipmentList(allOffersArray);
                this._updateObligationFilters(allOffersArray);
                this._updateAvailablePartners(allOffersArray);
                this.offers = this._updateFilteredOffers(this.offerKeys, this._bundlesBacked);

                this.scoringRules = this.offerKeys.length && this.scoringService.getScoringError(
                    this.offers[this.offerKeys[0]][0].fai
                );
                this._selectRecoveryOffer();
            },
            complete: () => this.isLoading = false
        });
        return of(null);
    }

    private _assignOffers(offersKeys: string[] = [], bundleBackups: IFaiOffers = {}): void {
        this._bundlesBacked = bundleBackups;
        this.offerKeys = offersKeys;
        this.offers = this._bundlesBacked;
    }

    private _updateEquipmentList(offers: IFaiPackBundle[]): void {
        this.filterEquipements = {all: EQUIPMENT_FILTER_LABELS.all};
        offers.forEach(o => {
            const oEquip: string = o.ocProduct.equipement;
            if (oEquip && EQUIPMENT_FILTER_LABELS[oEquip]) {
                this.filterEquipements[oEquip] = EQUIPMENT_FILTER_LABELS[oEquip];
            }
        });
    }

    private _updateObligationFilters(offers: IFaiPackBundle[]): void {
        this.sortByObligations = [{ label: 'Toutes les durées d\'engagement', key: 'all' }];
        const knownObligationsKeys = [
            { label: 'Engagement 12 mois', key: 'monthly12' },
            { label: 'Engagement 24 mois', key: 'monthly24' }
        ];
        offers.forEach(o => {
            const oligation: string = o.ocProduct.dureeEngagement;
            if (oligation && !this.sortByObligations.find(obj => obj.key === oligation)) {
                this.sortByObligations.push({ label: knownObligationsKeys.find(item => item.key === oligation)?.label, key: oligation });
            }
        });
    }

    private _updateAvailablePartners(offers: IFaiPackBundle[]): void {
        this.sortByPartners = [{ label: 'Aucun', key: 'none' }];
        offers.forEach((p) => {
            const offer = p.ocProduct;
            const filterExist = this.sortByPartners.some(filter => filter.key === offer?.partenaireCommercial);
            if (offer?.partenaireCommercial && !filterExist) {
                this.sortByPartners.push({ label: offer.partenaireCommercial, key: offer.partenaireCommercial });
            }
        });
    }

    private _updateFilteredOffers(offersKeys: string[], offersBundle: IFaiOffers): IFaiOffers {
        const ObjFromMyOffers = offersKeys.reduce((acc, curr)=>({...acc, [curr]: []}), {});

        const results: IFaiOffers = Object.keys(ObjFromMyOffers).reduce((acc, curr)=> {
            acc[curr] = offersBundle[curr].filter(o=>this._filterOfferByParams()(o));
            return acc;
        }, ObjFromMyOffers);
        return results;
    }

    @bind
    private _filterOfferByParams(): (offer: IFaiPackBundle) => boolean {
        return (offer): boolean => {
            const {offerType, sortByObligation, sortByEquipmentType, sortByPartner} = this.filterOffersByType.getRawValue();
            return this._filterOfferByType(offerType)(offer) &&
            this._filterOfferByObligation(sortByObligation)(offer) &&
            this._filterOfferByEquipement(sortByEquipmentType)(offer) &&
            this._filterOfferByPartner(sortByPartner)(offer);
        };
    }

    private _filterOfferByType(val: string): (offer: IFaiPackBundle) => boolean {
        switch (val) {
            case this.proOfferLabel:
                return (o: IFaiPackBundle): boolean =>
                    !o?.fai?.data.fai_category_type.includes(FaiCategoryType.OFFRE_SPECIALE) &&
                    !o?.fai?.data.fai_category_type.includes(FaiCategoryType.OFFRE_OLD);
            case FaiCategoryType.OFFRE_SPECIALE:
                return (o: IFaiPackBundle): boolean => o?.fai?.data.fai_category_type.includes(FaiCategoryType.OFFRE_SPECIALE);
            case FaiCategoryType.OFFRE_OLD:
                return (o: IFaiPackBundle): boolean => o?.fai?.data.fai_category_type.includes(FaiCategoryType.OFFRE_OLD);
            case FaiCategoryType.OFFRE_GP:
                return (o: IFaiPackBundle): boolean => o?.fai?.data.fai_category_type.includes(FaiCategoryType.OFFRE_GP);
            case 'all':
            default:
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                return (o: IFaiPackBundle): boolean => true;
        }
    }

    private _filterOfferByObligation(val: string): (offer: IFaiPackBundle) => boolean {
        return (o: IFaiPackBundle): boolean => val === 'all' || o?.ocProduct?.dureeEngagement?.includes(val);
    }

    private _filterOfferByEquipement(val: string): (offer: IFaiPackBundle) => boolean {
        return (o: IFaiPackBundle): boolean => val === 'all' || o?.ocProduct?.equipement === val;
    }

    private _filterOfferByPartner(val: string): (offer: IFaiPackBundle) => boolean {
        return (o: IFaiPackBundle): boolean =>
            (!o?.ocProduct?.partenaireCommercial && val === 'none') || o?.ocProduct?.partenaireCommercial?.includes(val);
    }

    @bind
    private _mapToJsonProduct(product: ISapicFaiProduct): Fai {
        return this.catalogService.mapFaiSapicToJsonProduct<Fai>(product);
    }

    private _buildReqHeader(gencodes: string[], offset: number = 0): any {
        const technologies = this.technoFilter?.map(f => `"${f.toUpperCase()}"`).join(',');

        let typeCategorieFai = `"${this.filterOffersByType.get('offerType').value}"`;
        if (this.filterOffersByType.get('offerType').value === 'offre_pro') {
            typeCategorieFai += ',"offre_gp"';
        }

        let filtre = `typeCategorieFai in (${typeCategorieFai}) and technologie in (${technologies})`;
        if (this._isEligibleToQuoteMixed) {
            filtre += ' and type="fai"';
        }

        return  {
            modePourFinancement: ModeFinancementEnum.AUTO,
            tri: 'prix-desc',
            prixReferencePourTri: 'initial',
            decalage: offset,
            detail: 1,
            limite: 40,
            gencodes,
            filtre,
            avecPromotionsApplicables: 1,
            ...(this._isEligibleToQuoteMixed ? {categorie: ALLOWED_CATEGORIES.SIMOFAI_TELESALES} : {})
        };
    }

    private _generateBodyParamsFromCart(): IContextualizedProductsParams {
        return {
            panier: {
                parcours: this._generateParcoursBodyParam(),
                client: !!this.customerService?.customer?.idPerson ?
                    { idPersonne: this.customerService?.customer?.idPerson }
                    : { categorie: this._getClientCategory() }
            }
        };
    }

    private _getClientCategory(): CUSTOMER_CATEGORY.GP | CUSTOMER_CATEGORY.PRO {
        if (this.customerService?.isPro() || this.filterOffersByType.get('offerType').value === 'offre_pro') {
            return CUSTOMER_CATEGORY.PRO;
        }
        return CUSTOMER_CATEGORY.GP;
    }

    @bind
    private _createManualPromotionZipCode(offer: ISapicFaiProduct): void {
        const promosWithPostalCode = offer?.promotionsApplicables
            ?.filter(p=>p?.proprietesSupplementaires?.typesMarketing.includes(TYPE_MARKETING.CODE_POSTAL_ELIGIBILITE_FIXE));
        if (promosWithPostalCode?.length && !this.offerWithManualPromotionZipCode.includes(offer.gencode)) {
            this.offerWithManualPromotionZipCode.push(offer.gencode);
        }

    }

    @bind
    private _getPromotionsDetails(offerBundle: IFaiPackBundle): Observable<IFaiPackBundle> {
        if (!offerBundle.ocProduct?.promotionsApplicables?.length) {
            return of(offerBundle);
        }
        return forkJoin(
            offerBundle.ocProduct.promotionsApplicables.map(
                (p: ISapicFaiProductPromotion)=>this.promotionsService.getPromotionDetails(p.identifiant, p.id)
            )
        ).pipe(
            map(pDetails=>pDetails.filter(p=>p.promoType === 'MANUELLE')),
            map(([...details])=>Object.assign({
                ...offerBundle,
                manualPromotionsDetails: {[offerBundle.ocProduct.gencode]: details}}
            ))
        );
    }

    private _generateParcoursBodyParam(): any {
        const plan: Plan = this.cartTelesalesService.cartModel.getQuote().getProductByType<Plan>('Plan');
        const faiPlan: Fai = this.cartTelesalesService.cartModel.getQuote().getProductByType<Fai>('Fai');
        const gencode = this._isEligibleToQuoteMixed ? this.cartTelesalesService.getPlanSimoInCart().gencode : '';
        if (plan && !faiPlan) {
            return [
                {
                    type: QUOTE_CONTEXTS.ACQUISITION,
                    produits: [{gencode, catalogue: CatalogType.BYTEL}],
                    estCourant: false
                },
                {
                    type: QUOTE_CONTEXTS.ACQUISITIONFIX,
                    produits: [],
                    estCourant: true
                }
            ];
        }
        return [
            {
                type: QUOTE_CONTEXTS.ACQUISITIONFIX,
                produits: [{gencode: faiPlan?.gencode ?? '', catalogue: CatalogType.BYTEL}],
                estCourant: true
            },
        ];
    }

}
