<ng-template #eligibilityMap>
    <div class="column is-narrow">
        <tlv-weto-eligibility-map></tlv-weto-eligibility-map>
    </div>
</ng-template>

<div>
    <ng-container [ngSwitch]="currentStep">
        <ng-container *ngSwitchDefault>
            <div class="columns is-vcentered">
                <div class="column">
                    <div class="is-flex is-justified-center">
                        <div class="notification is-small is-info has-body">
                            <div class="icon">
                                <i class="tri-exclamation-circle"></i>
                            </div>
                            <div class="body">
                                <p class="has-text-weight-semibold" *ngIf="orderRecovery.fai.address?.inline && orderRecovery.fai.type === FAI_CART_TYPE.ADDRESS">
                                    Adresse : {{orderRecovery.fai.address.inline}}
                                </p>
                                <p class="has-text-weight-semibold" *ngIf="orderRecovery.fai?.ndi && orderRecovery.fai.type === FAI_CART_TYPE.ND">
                                    Numéro de télephone : {{orderRecovery.fai.ndi}}
                                </p>
                                <p class="has-text-weight-semibold">
                                    Panier client :
                                </p>
                                <ul>
                                    <li *ngFor="let product of orderRecovery.products">- {{product.name}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="eligibilityMap"></ng-container>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="FAI_WIDGET_ROUTES.HOUSING">
            <div class="columns is-multiline">
                <div class="column">
                    <div class="is-flex is-justified-center">
                        <div class="notification is-small is-info has-body">
                            <div class="icon">
                                <i class="tri-exclamation-circle"></i>
                            </div>
                            <div class="body">
                                <p class="has-text-weight-semibold">
                                    Panier client :
                                </p>
                                <ul>
                                    <li *ngFor="let product of orderRecovery.products">- {{product.name}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="eligibilityMap"></ng-container>

                <div class="is-12 column">
                    <div class="is-flex is-justified-center" *ngIf="orderRecovery?.fai?.logement" data-cy="order-recovery-housing">
                        <div class="notification is-small is-info has-body">
                            <div class="body">
                                <h5 class="title">Logement renseigné lors de la première commande</h5>
                                <section class="is-flex is-justified-start">
                                    <div class="form-online size-twenty-four">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.nomTitulaire || ''" data-cy="order-recovery-housing-titular" type="text" class="input" aria-describedby="nomTitulaire" placeholder="Nom(s) de l'occupant" disabled>
                                        </div>
                                    </div>
                                    <div class="form-online size-twelve">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.ndi || ''" data-cy="order-recovery-housing-phone" type="text" class="input" placeholder="Tel." disabled>
                                        </div>
                                    </div>
                                    <div class="form-online size-five">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.numeroVoie || ''" data-cy="order-recovery-housing-streetNumber" type="text" class="input" disabled placeholder="N°">
                                        </div>
                                    </div>
                                    <div class="form-online size-twelve">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.residence || ''" data-cy="order-recovery-housing-residence" type="text" class="input" disabled placeholder="Résidence">
                                        </div>
                                    </div>
                                    <div class="form-online size-nine">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.batiment || ''" data-cy="order-recovery-housing-batiment" type="text" class="input" disabled placeholder="Bât.">
                                        </div>
                                    </div>
                                    <div class="form-online size-nine">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.etage || ''" data-cy="order-recovery-housing-etage" type="text" class="input" disabled placeholder="Etage">
                                        </div>
                                    </div>
                                    <div class="form-online size-eight">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.escalier || ''" data-cy="order-recovery-housing-escalier" type="text" class="input" disabled placeholder="Escalier">
                                        </div>
                                    </div>
                                    <div class="form-online size-eight">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.porte || ''" data-cy="order-recovery-housing-porte" type="text" class="input" disabled placeholder="Porte">
                                        </div>
                                    </div>
                                    <div class="form-online size-seven">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.logo || ''" data-cy="order-recovery-housing-logo" type="text" class="input" placeholder="Logo" disabled>
                                        </div>
                                    </div>
                                    <div class="form-online size-seven">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.logement.ndStatut || ''" data-cy="order-recovery-housing-statut" type="text" class="input" placeholder="Etat" disabled>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="FAI_WIDGET_ROUTES.PTO">
            <div class="columns">
                <div class="column">
                    <div *ngIf="orderRecovery?.fai?.pto" data-cy="order-recovery-pto">
                        <div class="notification is-small is-info has-body">
                            <div class="body">
                                <h5 class="title">Logement renseigné lors de la première commande</h5>
                                <section class="is-flex is-justified-start">
                                    <div class="form-online size-twenty-five">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.pto.batiment || ''" data-cy="order-recovery-pto-batiment" type="text" class="input" disabled placeholder="Bât.">
                                        </div>
                                    </div>
                                    <div class="form-online size-twenty-five">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.pto.escalier || ''" data-cy="order-recovery-pto-escalier" type="text" class="input" disabled placeholder="Escalier">
                                        </div>
                                    </div>
                                    <div class="form-online size-twenty-five">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.pto.etage || ''" data-cy="order-recovery-pto-etage" type="text" class="input" disabled placeholder="Etage">
                                        </div>
                                    </div>
                                    <div class="form-online size-twenty-five">
                                        <div class="control">
                                            <input [value]="orderRecovery.fai.pto.currentPto || ''" data-cy="order-recovery-pto-ptoId" type="text" class="input" disabled placeholder="PTO">
                                        </div>
                                    </div>
                                </section>
                                <br/>
                                <p class="is-size-6" data-cy="order-recovery-pto-fiber">Prise optique connue du client: <span class="has-text-weight-semibold">{{!!orderRecovery.fai.pto.currentPto ? 'OUI' : 'NON'}}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="eligibilityMap"></ng-container>
            </div>
        </ng-container>
    </ng-container>
</div>