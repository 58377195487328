

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Fai } from '@bytel/bytel-sales';
import bind from '@helper/decorators/bind';
import { FaiTechnoModel } from '@models/fai/fai-techno.model';
import { OrderModel } from '@models/order/order.model';
import { CatalogService } from '@services/catalog.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { SalesForceService } from '@services/salesforce.service';
import { differenceInMonths } from 'date-fns';
import {
    CLASSIFICATION,
    FAI_ELIGIBILITY_STATUS,
    FAI_ROUTES,
    FAI_TECHNO,
    FAI_TECHNO_PON
} from 'src/app/constants/fai';
import { ALLOWED_TYPES } from '../eligibility/eligibility.component';

@Component({
    selector: 'tlv-technos',
    templateUrl: './technos.component.html',
    styleUrls: ['./technos.component.scss']
})
export class TechnosComponent implements OnInit {

    public static readonly PLAY_TV: string = '3P';
    public readonly faiStatus: typeof FAI_ELIGIBILITY_STATUS = FAI_ELIGIBILITY_STATUS;
    public isCDL: boolean;
    public technos: FaiTechnoModel[] = [];
    public orderRecovery: OrderModel;
    public warningMessageCDLOrderReco: boolean;
    public hasNoAdslEligible: boolean = false;
    public hasNoOneEligible: boolean = false;
    public lockOnSingleTechnoAvailable: boolean = false;
    public isLoading: boolean = false;
    public selectedTechno: { [index: string]: boolean } = {};
    public offreByTechno: { [index: string]: Fai[] } = {};

    constructor(
        protected router: Router,
        private salesForceService: SalesForceService,
        private catalogService: CatalogService,
        private faiEligibilityService: FaiEligibilityService,
        @Inject(DOCUMENT) protected document: Document
    ) {
        const currentFaiCart = this.faiEligibilityService.currentCart;
        this.technos = [
            currentFaiCart.eligibility.adsl,
            currentFaiCart.eligibility.vdsl,
            currentFaiCart.eligibility.ftth,
            ...(currentFaiCart.eligibility.box_4g ? [currentFaiCart.eligibility.box_4g] : []),
            ...(currentFaiCart.eligibility.box_5g ? [currentFaiCart.eligibility.box_5g] : [])
        ];
        this.isCDL = currentFaiCart.eligibility.isCDL;
    }

    public ngOnInit(): void {
        this.hasNoAdslEligible = this.technos.some((techno) =>
            techno.type !== FAI_TECHNO.ADSL && techno.status === FAI_ELIGIBILITY_STATUS.OK);
        this.hasNoOneEligible = this.technos.every((techno) => techno.status !== FAI_ELIGIBILITY_STATUS.OK);
        this.selectedTechno = this.technos.reduce((acc,techno) => {
            acc[techno.type] = (techno.type === FAI_TECHNO.ADSL && this.hasNoAdslEligible) ? false :
                (techno.status === FAI_ELIGIBILITY_STATUS.OK);
            return acc;
        },{});

        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        this.warningMessageCDLOrderReco = this.orderRecovery?.fai?.isCDL;
        this.lockOnSingleTechnoAvailable = this.technos.filter(tech=>tech.status === this.faiStatus.OK)?.length === 1;

        for (const techno of this.technos){
            if (techno.offres.length) {
                this.catalogService.getProductsByGencodes<Fai>(techno.offres).subscribe(this._setOfferOfTechno(techno.type));
            }
        }
    }

    public goToPreviousNext(): void {
        let step: FAI_ROUTES = this.faiEligibilityService.currentCart?.eligibility?.ftth?.status === FAI_ELIGIBILITY_STATUS.OK
            ? FAI_ROUTES.PTO : FAI_ROUTES.HOUSING;

        if (this.faiEligibilityService.currentWidget === ALLOWED_TYPES.NUMBER) {
            step = FAI_ROUTES.NUMBER;
        }
        this.router.navigateByUrl(step);
    }

    public submit(): void {
        this.router.navigateByUrl(
            `${FAI_ROUTES.OFFERS}?techno=${Object.keys(this.selectedTechno).filter((key)=>this.selectedTechno[key])}`
        );
    }

    public canHaveTV(techno: FaiTechnoModel): boolean{
        if (techno.type === FAI_TECHNO.BOX_5G || techno.type === FAI_TECHNO.BOX_4G) {
            return this.offreByTechno[techno.type]?.some(product => product.data.classification === CLASSIFICATION.MEDIUM_TV_XGBOX);
        }
        return this.offreByTechno[techno.type]?.some(product => product.play === TechnosComponent.PLAY_TV);
    }

    public convertToReadableDate(date: string): string {

        if (!date || new Date(date).getTime() < Date.now()) {
            return;
        }

        const now: Date = new Date();
        const forecastDate: Date = new Date(date);
        const eligibilityDate = 'Eligible ';

        switch (true) {
            case differenceInMonths(forecastDate, now) <= 3:
                return eligibilityDate.concat('sous 3 mois');
            case differenceInMonths(forecastDate, now) <= 6:
                return eligibilityDate.concat('sous 4 à 6 mois');
            case differenceInMonths(forecastDate, now) >= 6:
                return eligibilityDate.concat('sous 12 mois');
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public gotoForecastForm(techno: FaiTechnoModel): void {
        this.document.defaultView.open(this.faiEligibilityService.getForecastUrlInfos(techno));
    }

    public isTechnologiesPon(tech: FaiTechnoModel): boolean {
        return (tech.technologiesPon && tech.technologiesPon?.includes(FAI_TECHNO_PON.XGSPON));
    }

    @bind
    private _setOfferOfTechno(techno: FAI_TECHNO): (products: Fai[]) => void{
        return (products: Fai[]): void => {
            this.offreByTechno[techno] = products.filter((product) => product);
        };
    }
}
