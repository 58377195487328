<div class="smart-tv-list">
    <div class="row">
        <div class="col">
            <div class="is-tri">
                <h3 class="data-line-details-title">Sélectionnez votre référence de TV</h3>
                <div class="field tv-list-input">
                    <div class="control has-dynamic-placeholder has-icons-right ">

                        <input #serialInput #smartTvListInput="ngModel" class="input" placeholder="Saisissez la référence de votre téléviseur" id="serial" name=""
                               type="text" tabindex="1" required autocomplete="nope" [(ngModel)]="tvSelected"
                               [ngClass]="{'is-danger': smartTvListInput.dirty && !isValidForm(), 'is-success': smartTvListInput.dirty &&  smartTvListInput.valid}"
                        >
                        <label for="serial" class="label-placeholder">Saisissez la référence de votre téléviseur</label>
                        <span class="icon" *ngIf="smartTvListInput.dirty && !isValidForm()">
                            <i class="tri-exclamation-circle"></i>
                        </span>
                        <span class="icon" *ngIf="smartTvListInput.dirty && isValidForm()">
                            <i class="tri-check-circle"></i>
                        </span>
                    </div>
                    <small *ngIf="smartTvListInput.dirty && !isValidForm()" class="has-text-danger is-block has-text-left">
                        Merci de saisir une référence de téléviseur connue
                    </small>
                </div>
            </div>
        </div>
    </div>
    <div class="row where-to-find">
        <div class="col">
            <div class="is-tri">
                <p class="has-text-tertiary is-size-6">Où se trouve votre référence ?</p>
                <div class="box has-background-grey-light is-shadowless">
                    <div class="columns">
                        <div class="column is-half has-text-centered">
                            <div class="img-container">
                                <img src="assets/images/smart-tv-list-arriere.png" class="image multiply" alt="Trouver la référence derrière le téléviseur">
                            </div>
                            <small class="is-block is-size-8">
                                Sur l'étiquette à l'arrière de votre&nbsp;TV
                            </small>
                        </div>
                        <div class="is-divider is-vertical-divider-from-tablet has-background-white"></div>
                        <div class="column is-half has-text-centered has-divider">
                            <div class="img-container">
                                <img src="assets/images/smart-tv-list-menu.png" class="image" alt="Trouver la référence dans les menus du téléviseur">
                            </div>
                            <small class="is-block is-size-8">
                                Sur les paramètres de votre TV: Menu&nbsp;> Paramètres&nbsp;> Assistance&nbsp;> À propos de votre TV
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
