export enum ELIGIBILITY_SOURCE_STEP {
    FUNNEL = 'Adresse par entonnoir',
    SINGLE_BAR = 'Adresse par barre unique',
    ND = 'Numéro de téléphone',
    PTO = 'Eligibilité par PTO',
    POI = 'Eligibilité par carte',
}

export enum TRACKING_CALL_TYPE {
    ELIGIBILITY_RESSOURCE = 'eligibilityCall',
    API_ERROR = 'apiRessourceCall'
}
