import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressModel } from '@models/cart/address.model';
import { PortabilityModel } from '@models/cart/portability.model';
import { CompanyModel } from '@models/customer/company.model';
import { AddressValidationModel } from '@models/validators/address-validation.model';
import { EmailValidationModel } from '@models/validators/email-validation.model';
import { PortaValidationModel } from '@models/validators/porta-validation.model';
import { ValidatorsRepository } from '@repositories/validators.repository';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
export interface IFormPortability {
    checked?: boolean;
    phoneNumber: string;
    codeRio: string;
}

@Injectable({
    providedIn: 'root'
})
export class ValidatorsService {

    constructor(
        private validatorsRepository: ValidatorsRepository,
        private faiEligibilityService: FaiEligibilityService
    ) { }

    public portaValidationFai(noTelephoneAPorter: string,codeRio: string,porta: PortabilityModel): Observable<PortaValidationModel>{
        return this.validatorsRepository.portaValidationFai(
            noTelephoneAPorter,
            codeRio,
            this.faiEligibilityService.currentCart.id,
            porta
        );
    }
    public portaValidation(noTelephoneAPorter: string, codeRio: string): Observable<PortaValidationModel>{
        return this.validatorsRepository.portaValidation(
            noTelephoneAPorter,
            codeRio
        );
    }

    public mailValidation(email: string): Observable<EmailValidationModel> {
        return this.validatorsRepository.mailValidation(email).pipe(
            tap((emailValidation)=>{
                if (!emailValidation.validEmail || emailValidation.domainBbox){
                    throw emailValidation;
                }
            })
        );
    }

    public sirenValidation(siren: string): Observable<CompanyModel> {
        return this.validatorsRepository.sirenValidation(siren);
    }

    public addressValidation(address: AddressModel): Observable<AddressValidationModel> {
        return this.validatorsRepository.addressValidation(address);
    }

    public rnaNumberValidation(rnaNumber: string): Observable<HttpResponse<any>> {
        return this.validatorsRepository.rnaNumberValidation(rnaNumber.toUpperCase());
    }
}
