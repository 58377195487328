<div class="is-flex is-justified-center has-text-left" data-cy="fai-steps-summary" *ngIf="stepsInfos.length">
    <div class="notification is-small is-info has-body">
        <div class="icon">
            <i class="tri-exclamation-circle"></i>
        </div>
        <div class="body">
            <ul>
                <li *ngFor="let step of stepsInfos">
                    <span class="has-text-weight-semibold">{{step.split('#')[0]}}</span>
                    <ng-container *ngIf="step.split('#')[1]">: {{step.split('#')[1]}}</ng-container>
                </li>
            </ul>
        </div>
    </div>
</div>