<div class="columns">
    <div class="column is-12">
        <h2 class="icon-and-text has-cursor" (click)="back()" *ngIf="!firstStepValue">
            <span class="icon is-small is-circled is-small has-background-tertiary has-text-tertiary-invert"><i
                    class="tri-arrow-left"></i></span>
            <span>{{CTA_BACK_LABELS[currentStep] ?? 'Retour'}}</span>
        </h2>
        <div class="is-divider" *ngIf="!firstStepValue"></div>
    </div>
</div>

<tlv-weto-fai-order-recovery-summary [currentStep]="currentStep" *ngIf="orderRecovery"></tlv-weto-fai-order-recovery-summary>

<div class="columns is-vcentered" *ngIf="!firstStepValue && !orderRecovery">
    <div class="column is-6 is-offset-3">
        <tlv-steps-summary-data></tlv-steps-summary-data>
    </div>
    <div class="column is-3 is-narrow has-text-right icon-map">
        <tlv-weto-eligibility-map></tlv-weto-eligibility-map>
    </div>
</div>