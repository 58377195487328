import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ConfigurationService } from '@common-modules';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WetoService {
    public script: HTMLScriptElement;
    public widgetAlreadyLoaded: boolean = false;
    private renderer: Renderer2;

    constructor(
        protected configService: ConfigurationService,
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public resolve(): Observable<void> {
        this.loadWetoScript();
        return of(null);
    }

    public loadWetoScript(): void {
        const widgetUrl = this.configService.data_refconf?.fai?.widget.url_a7; // WIDGET SCRIPT ON CLOUD (A7)
        // const widgetUrl = this.configService.data_refconf?.fai?.widget.url_assets; // WIDGET SCRIPT ON LOCAL (BUILD)
        const widgetScript = this.document.querySelectorAll(`script[src='${widgetUrl}']`);
        this.widgetAlreadyLoaded = widgetScript.length > 0;
        if (!this.widgetAlreadyLoaded) {
            this.script = this.renderer.createElement('script');
            this.script.type = 'text/javascript';
            this.script.src = widgetUrl;
            this.renderer.appendChild(this.document.body, this.script);
            this.widgetAlreadyLoaded = true;
        }
    }
}
