import { Component, EventEmitter, Output } from '@angular/core';
import { WetoComponent } from '../weto/weto.component';
import { FAI_WIDGET_ROUTES } from 'src/app/constants/fai';
import { PartialAddress, WetoFunnelProps } from 'src/app/common-modules/interfaces/widget/weto-funnel-props.interface';

@Component({
    selector: 'tlv-weto-map',
    templateUrl: './weto-map.component.html',
    styleUrls: ['./weto-map.component.scss']
})
export class WetoMapComponent extends WetoComponent  {
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public override end = new EventEmitter<boolean>(true);

    public FAI_WIDGET_ROUTES = FAI_WIDGET_ROUTES;
    public isStreetViewActive = false;

    public render(): void {
        this.isStreetViewActive = this.configService.data_refconf.fai.widget.isActiveStreetView;
        const funnelRenderParams: WetoFunnelProps =
            this.eligibilityService.buildAddressRestoreFromRecovery(this.salesForceService.prefilledInfo?.order?.fai?.address) ??
            this.eligibilityService.buildAddressRestoreFromData();
        const partialAddresss: PartialAddress = funnelRenderParams?.partialAddresss ??
            this.eligibilityService.buildAddressRestoreFromSalesForce(this.salesForceService.prefilledInfo?.address);

        this.widget?.nativeElement.render(
            {
                hiddenController: true,
                typeVente: 'VA',
                typeActeur: 'CDC',
                enablePersistentData: true,
                api: {
                    baseUrl: this.configService.baseConfig.resourceUrl,
                    token: this.oauth2Service.access_token ?? null,
                    xSource: 'portailvente_tlv'
                }
            },
            this.isStreetViewActive,
            partialAddresss,
        );

        this._ngZone.runOutsideAngular(() => {
            this.widget?.nativeElement.onEvent(this.onEvent.bind(this));
        });
    }

}
