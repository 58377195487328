import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Fai } from '@bytel/bytel-sales';
import { FaiCartModel } from '@models/fai/fai-cart.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { SalesForceService } from '@services/salesforce.service';
import { FAI_ELIGIBILITY_STATUS, FAI_ROUTES } from 'src/app/constants/fai';

@Component({
    selector: 'tlv-steps-summary-data',
    templateUrl: './steps-summary-data.component.html',
    styleUrls: ['./steps-summary-data.component.scss']
})
export class StepsSummaryDataComponent implements OnInit {

    public stepsInfos: string[] = [];
    private _faiCart: FaiCartModel;

    constructor(
        private faiEligibilityService: FaiEligibilityService,
        private salesForceService: SalesForceService,
        private route: ActivatedRoute,
        private cartService: CartTeleSalesService,
        protected router: Router,
    ) { }

    public ngOnInit(): void {
        this._faiCart = this.faiEligibilityService.currentCart;
        this._generatePreviousStepsInfos();
        this._cleanInfos();
    }

    private _generatePreviousStepsInfos(): void {
        if (this.salesForceService.prefilledInfo?.order) {
            return;
        }

        if ([`${FAI_ROUTES.ADDRESS}`, `${FAI_ROUTES.NUMBER}`].includes(this.router.url)) {
            return;
        }

        const address: string = this._generateAddress();
        const housing: string = this._generateHousingInfo();
        const technos: string = this._generateTechos();

        const isEligibleFtth = this._faiCart?.eligibility?.ftth.status !== FAI_ELIGIBILITY_STATUS.NOK;
        const pto: string = isEligibleFtth ? this._generatePtoMsg() : null;
        // const faiOffer: string = this._generateOffer(); // TODO: add only for cart recovery

        this.stepsInfos.push(address);

        switch (true) {
            case this.router.url.includes(`${FAI_ROUTES.TECHNO}`):
                this.stepsInfos.push(pto ?? housing);
                return;
            case this.router.url.includes(`${FAI_ROUTES.OFFERS}`):
                this.stepsInfos.push(pto ?? housing);
                this.stepsInfos.push(technos);
                return;
            case this.router.url.includes(`${FAI_ROUTES.PTO}`):
                this.stepsInfos = [
                    ...this.stepsInfos,
                    housing,
                    ...(technos ? [technos] : []),
                    // faiOffer
                ];
                return;
        }
    }

    private _cleanInfos(): void {
        this.stepsInfos = this.stepsInfos.filter((txt: string) => !!txt);
    }

    private _generateAddress(): string {
        if (this._faiCart.searchNumber) {
            return `Numéro # ${this._faiCart.searchNumber}`;
        }
        return `Adresse # ${this._faiCart.searchAddress.getAddressModel().getInline().replace(/_none/, '')}`;
    }

    private _generateHousingInfo(): string {
        if (!this._faiCart.selectedLogement ||
            this._faiCart.selectedLogement?.logementNonTrouve) {
            return;
        }

        const {nomTitulaire, etage, escalier, porte } = this._faiCart.selectedLogement;
        if (!nomTitulaire) {return;}
        const step = etage && `Etage: ${etage}`;
        const floor = escalier && `Escalier: ${escalier}`;
        const door = porte && `Porte: ${porte}`;
        return `Occupant # ${nomTitulaire} ${step ?? floor ?? door ?? ''}`;
    }

    private _generateTechos(): string {
        const data = this.route.snapshot.queryParams;
        let technos: string[] = Array.isArray(data.techno) ? data.techno : [data.techno].filter(t => !!t);

        const currentFaiCart = this.faiEligibilityService?.currentCart;
        const availableTechnos = currentFaiCart.eligibility ? [
            currentFaiCart.eligibility.adsl,
            currentFaiCart.eligibility.vdsl,
            currentFaiCart.eligibility.ftth,
            ...(currentFaiCart.eligibility.box_4g ? [currentFaiCart.eligibility.box_4g] : []),
            ...(currentFaiCart.eligibility.box_5g ? [currentFaiCart.eligibility.box_5g] : [])
        ].filter(techno => techno.status === FAI_ELIGIBILITY_STATUS.OK).map(tech => tech.type.toUpperCase()) : [];

        if (!technos.length && !availableTechnos.length) { return; }
        if (!technos.length) { technos = availableTechnos; }

        const isPlural = technos.length > 1;
        return `Techno${isPlural ? 's' : ''} disponible${isPlural ? 's' : ''} pour ce client # ${technos.map(t => t.toUpperCase())}`;
    }

    private _generatePtoMsg(): string {
        const verticalStructure = this._faiCart?.selectedVerticalStructure;
        if (verticalStructure?.ptoSelected) {
            return `Pto choisie # ${verticalStructure.ptoSelected}`;
        } else if (!verticalStructure?.presencePto) {
            return 'Le client ne possède pas de PTO';
        }
    }

    private _generateOffer(): string {
        const faiOffer: Fai = this.cartService.cartModel.getQuote().getProductByType('Fai');
        if (!faiOffer) {
            return;
        }
        return `Offre choisie # ${faiOffer.name} - (${faiOffer.gencode})`;
    }

}
