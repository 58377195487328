import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '@common-modules';
import { ACTIONS } from '@directives/prisme-logger.directive';

@Component({
    selector: 'tlv-weto-eligibility-map',
    templateUrl: './weto-eligibility-map.component.html',
    styleUrls: ['./weto-eligibility-map.component.scss']
})
export class WetoEligibilityMapComponent implements OnInit {

    public LOG_ACTIONS: typeof ACTIONS = ACTIONS;
    public eligibilityMapUrl: string = '';

    constructor(protected configService: ConfigurationService) { }

    public ngOnInit(): void {
        this.eligibilityMapUrl = this.configService.data_refconf?.fai?.eligibilityMapUrl;
    }
}
