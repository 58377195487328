import { Pipe, PipeTransform } from '@angular/core';
import { PromotionsModel } from '@bytel/bytel-sales';

@Pipe({
    name: 'getPromotionAmount'
})
export class GetPromotionAmount implements PipeTransform {
    public transform(promotions: PromotionsModel): string {
        let amount = 0;
        if (promotions) {
            amount = promotions.automatic.concat(promotions.manual).reduce((acc, promo) => acc + promo.getAmount(amount), 0);
        }
        return Boolean(amount) ? amount.toFixed(2) + '€' : '--';
    }
}
