import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SalesForceFaiService } from '@services/salesforce-fai.service';
import { FAI_CBR_ROUTES } from 'src/app/constants/fai';

import { EligibilityComponent } from '@components/fai-widget/eligibility/eligibility.component';
import { OffersComponent } from './offers/offers.component';
import { TechnosComponent } from './technos/technos.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: FAI_CBR_ROUTES.ADDRESS,
        pathMatch: 'full'
    },
    {
        path: FAI_CBR_ROUTES.NUMBER,
        component: EligibilityComponent,
        data: {
            type: EligibilityComponent.TYPES.NUMBER
        }
    },
    {
        path: FAI_CBR_ROUTES.ADDRESS,
        component: EligibilityComponent,
        resolve: [SalesForceFaiService],
        data: {
            type: EligibilityComponent.TYPES.ADDRESS
        }
    },
    {
        path: FAI_CBR_ROUTES.PTO,
        component: EligibilityComponent,
    },
    {
        path: FAI_CBR_ROUTES.HOUSING,
        component: EligibilityComponent,
    },
    {
        path: FAI_CBR_ROUTES.TECHNO,
        component: TechnosComponent
    },
    {
        path: FAI_CBR_ROUTES.OFFERS,
        component: OffersComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FaiWidgetRoutingModule { }
